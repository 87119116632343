import { observer } from 'mobx-react';
import React from 'react';
import { Grid, createStyles, makeStyles, Theme, Paper, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { TimeSheetHeaderModel } from '../domain/TimeSheetHeader';
import blue from '@material-ui/core/colors/blue';

interface Props {
  header: TimeSheetHeaderModel;
  disabled?: boolean;

  onChange: (timeSheetHeader: TimeSheetHeaderModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2)
    },
    label: {
      color: blue[500]
    }
  }));

export const TimeSheetHeader: React.FC<Props> = observer((props) => {
  const { header, onChange, disabled } = props;
  const classes = useStyles();

  const onMealAllowanceChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newHeader = { ...header };
    newHeader.mealAllowance = checked;

    onChange(newHeader);
  };

  const onPhOnCallChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newHeader = { ...header };
    newHeader.phOnCall = checked;

    onChange(newHeader);
  };

  const onOnCallChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newHeader = { ...header };
    newHeader.onCall = checked;

    onChange(newHeader);
  };

  return (
    <Paper className={classes.container}>
      <Grid container={true} spacing={5}>
        <Grid item={true} md={6} xs={12}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={3}>
              <TextField
                disabled={true}
                label="Team ID"
                value={header.team}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextField
                disabled={true}
                label="Technician ID"
                value={header.technicianId}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextField
                disabled={true}
                label="Date"
                value={header.formattedDate}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextField
                disabled={true}
                label="Shift"
                value={header.shift}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={header.mealAllowance}
                    onChange={onMealAllowanceChange}
                    color="primary"
                  />
                }
                label="MEAL ALLOWANCE"
                className={classes.label}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={true}
                    checked={header.phOnCall}
                    onChange={onPhOnCallChange}
                    color="primary"
                  />
                }
                label="P/H ON CALL"
                className={classes.label}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={header.onCall}
                    onChange={onOnCallChange}
                    color="primary"
                  />
                }
                label="ON CALL"
                className={classes.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
