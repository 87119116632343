import { Instance, types, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { EstimateList } from '../domain/EstimateList';

export const EstimatesListStore = types
  .model({
    ID: types.optional(types.string, ''),
    isLoading: types.optional(types.boolean, false),
    estimatesList: types.optional(types.array(EstimateList), []),
    ViewAll: types.optional(types.boolean, false),
    searchPhrase: types.optional(types.string, ''),
    statusesList: types.array(types.model({
      id: types.string,
      value: types.string
    })),
    status: types.optional(types.string, ''),
    statusName: types.optional(types.string, ''),
    Branch: types.optional(types.string, ''),
    isChecked: types.optional(types.boolean, false),
    isEstimateEditor: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const { api, auth } = getEnv<IStoresEnv>(self);


    const checkUserRole = flow(function* checkUserRole() {
      // Check if the user's roles include 'EstimateEditor'
      if (auth.user?.isEstimateEditor) {
        self.isEstimateEditor = true;
      } else {
        self.isEstimateEditor = false;
        self.isChecked = false;
      }
    });

    const setInitialCheckboxState = () => {
      // Check if the user's roles include 'EstimateEditor'
      if (auth.user?.isEstimateEditor) {
        self.isChecked = true;
      }
    };

    const getStatuses = flow(function* getStatuses() {
      try {
        const response = yield api.get(`/api/EstimateStatusConfig`);
        const data = response.data.statuses || [];
        self.statusesList = data.map((x) => ({ id: x.id, value: x.status })) || [];
        const newStatus = self.statusesList.find(s => s.value.toLowerCase() === 'new');
        if (newStatus) {
          self.status = newStatus.id;
        }
      } catch (error) {
        console.error('Failed to fetch statuses:', error);
      }
    });

    const getBranchID = flow(function* getBranchID() {
      const branchID = yield api.get(`/api/EstimateBranchConfig/id?office=${auth.officeLocation}`);
      self.Branch = branchID.data.id || '';
      self.ViewAll = branchID.data.viewAll || false;
    });

    const getStatus = flow(function* getStatus() {
      const response = yield api.get(`/api/EstimateStatusConfig/id?statusID=${self.status}`);
      self.statusName = response.data.status || 'All';
    });

    const getAllEstimates = flow(function* getAllEstimates(status: string) {
      self.isLoading = true;
      yield getBranchID();
      yield getStatus();
      yield checkUserRole();
      try {
        let response;
        if (self.isChecked) {
          if (auth.user) {
            response = yield api.get(`/api/Estimates/getall?status=${encodeURIComponent(self.statusName)}&branch=${self.Branch}&viewAll=${self.ViewAll}&searchPhrase=${self.searchPhrase}&owner=${auth.user.name}`);
          }
        }

        else {
          response = yield api.get(`/api/Estimates/getall?status=${encodeURIComponent(self.statusName)}&branch=${self.Branch}&viewAll=${self.ViewAll}&searchPhrase=${self.searchPhrase}`);
        }
        //  const response = yield api.get(`/api/Estimates/getall?status=${encodeURIComponent(self.statusName)}&branch=${self.Branch}&viewAll=${self.ViewAll}&searchPhrase=${self.searchPhrase}`);
        self.estimatesList = response.data.map((x) => ({
          ID: x.id,
          SubmittedDate: x.submittedDate || '',
          SubmittedBy: x.submittedBy || '',
          EstimateNr: x.estimateNr || '',
          Revision: x.revision || 0,
          Priority: x.priority || '',
          FleetType: x.fleetType || '',
          DebtorID: x.debtorID || '',
          CustomerName: x.customerName || '',
          Location: x.location || '',
          EquipmentID: x.equipmentID || '',
          Make: x.make || '',
          Model: x.model || '',
          JobDescription: x.jobDescription || '',
          Status: x.status || '',
          Owner: x.owner || '',
          Branch: x.branch || '',
        }))
      }
      catch (error) {
        console.error('Failed to fetch all estimates:', error);
      } finally {
        self.isLoading = false;
      }
    });


    return {

      selectStatus(status: string) {
        self.status = status;
        getAllEstimates(status);
      },

      setSearchPhrase(searchPhrase: string) {
        self.searchPhrase = searchPhrase;
      },

      validateSearchPhrase(searchPhrase: string) {
        self.searchPhrase = searchPhrase;
        getAllEstimates(self.status);
      },

      setChecked(isChecked: boolean) {
        self.isChecked = isChecked;
        getAllEstimates(self.status);
      },

      getStatuses,
      checkUserRole,
      setInitialCheckboxState,
      getAllEstimates,

    };
  })



  ;

export type IEstimatesListStore = Instance<typeof EstimatesListStore>;


