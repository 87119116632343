import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { blueGrey, green, lightBlue, red } from '@material-ui/core/colors';
import { ITechnicianTeam, PayrollSubmissionStatus } from 'pay-period-submission/domain';
import { Button, Grid, Typography } from '@material-ui/core';
import { ArrowUpwardOutlined, FiberManualRecordOutlined } from '@material-ui/icons';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { formatDate_ddmmyyyy } from '@utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    },
    container: {
      padding: theme.spacing(1)
    },
    title: {
      paddingBottom: theme.spacing(1),
      color: red[700],
    },
    error: {
      color: red[700],
      paddingLeft: theme.spacing(1)
    },
    success: {
      color: green[700]
    },
    icon: {
      verticalAlign: 'text-top',
      marginRight: '5px'
    }
  }));

interface Props {
  team: ITechnicianTeam;
  integrate: (technicianTeam: string) => any
}

export default function TechnicianTeamRow(props: Props) {
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { team, integrate } = props;

  const handleIntegrate = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = (confirmed: boolean) => {
      setConfirmationOpen(false);
      if (integrate && confirmed) {
        integrate(team.teamName);
      }
  };

  return (
    <TableRow key={team.teamName} className={classes.row}>
      <TableCell component="th" scope="row" align="left">
        {team.teamName}
      </TableCell>
      <TableCell align="left">{team.numberOfUsers}</TableCell>
      <TableCell align="left">{team.integratedUpTo && team.integratedUpTo != '0001-01-01T00:00:00' ? formatDate_ddmmyyyy(new Date(team.integratedUpTo)) : ''}</TableCell>
      <TableCell align="left">
          <Button
            className={classes.label}
            color="primary"
            startIcon={<ArrowUpwardOutlined />}
            onClick={handleIntegrate}
          >
            Integrate
          </Button>
      </TableCell>
      { team.status === '' ? <TableCell align="left"> <Grid xs={6}
                className={classes.container}
                container={true}
              ></Grid></TableCell> : 
          team.status !== PayrollSubmissionStatus.Success ? 
            <TableCell align="left">
              <Grid xs={12}
                className={classes.container}
                container={true}
              >
                <Grid item={true} xs={12}>
                  <Typography variant="subtitle1" className={classes.title}>
                  {team.status === PayrollSubmissionStatus.PartialSuccess ? 'The following errors must be corrected by manually editing data in Payroll Metrics'
                    : team.status === PayrollSubmissionStatus.Failed ? 'The following errors needs to be fixed before integrating the timesheets' : ''}
                  </Typography>
                </Grid>
                {team.errors.map((x, i) => (
                  <Grid item={true} xs={12} key={i}>
                    <Typography noWrap={true} className={classes.error}>
                      <FiberManualRecordOutlined fontSize="inherit" className={classes.icon} />
                      {x}
                    </Typography>
                  </Grid>
                ))}
                </Grid>
              </TableCell> : 
          <TableCell align="left" className={classes.success}>Successfully Integrated</TableCell>
          }
          <ConfirmationDialog
              open={confirmationOpen}
              onClose={handleConfirmationClose}
              declineButtonTitle="No"
              confirmButtonTitle="Yes"
              title="Are you sure you want to run pay submission for this team?"
          />
    </TableRow>
  );
}
