import { flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';



export const setPartAPI = (model: any, debtorID: string, partNr: string) => {
  const { api } = getEnv<IStoresEnv>(model);
  const fetchData = flow(function* fetch() {
    const PartResponse = yield api.get(
      `/api/ServiceEstimate/PartsDetails?debtorID=${encodeURIComponent(debtorID)}&itemNumber=${encodeURIComponent(partNr)}`);
    model.DebtorID = debtorID;
    model.partNr = partNr;
    if (PartResponse.status === 204) {
      model.partDesc = '';
      model.stdCost = 0;
      model.currCost = 0;
      model.sellPrice = 0;
      model.qtyOnHand = 0;

      model.PartNrError = `Part doesn't exist`
    } else if (PartResponse.status === 200) {
      model.partNr = (PartResponse.data.actualPartNumber || '').trim();
      model.partDesc = (PartResponse.data.itemDescription || '').trim();
      model.stdCost = PartResponse.data.standarCost || 0;
      model.currCost = PartResponse.data.currentCost || 0;
      model.sellPrice = PartResponse.data.itemSellPrice || 0;
      model.qtyOnHand = PartResponse.data.nationalQuantityOnHand || 0;
      model.PartNrError = '';
    } else {
      model.partDesc = '';
      model.stdCost = 0;
      model.currCost = 0;
      model.sellPrice = 0;
      model.qtyOnHand = 0;
      model.PartNrError = `Failure retrieving part details`;
    }
  });
  fetchData();
};


